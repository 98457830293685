import { useRouter } from "next/navigation";
import { useFormik } from "formik";
import { contactUs } from "@/Services/Validator/Form/ContactUs/contactUs";
import React, { useEffect, useState } from "react";
import { ContactUsApiType, ContactUsFormTypes } from "@/Types/FormTypes";
import { contactFormApi } from "@/Services/apis/auth.api";
import { toast } from "react-toastify";
import { getAppVersion } from "@/Utils/CommonFuncs";
import { v4 as uuidv4 } from "uuid"; // Import the uuid function

export const useLanding = () => {
  // Hooks calls
  const router = useRouter();
  const contactUsFormik = useFormik<ContactUsFormTypes>({
    initialValues: contactUs.initialValues,
    validationSchema: contactUs.validationSchema,
    onSubmit: async (values) => {
      try {
        const bodyData: ContactUsApiType = {
          name: `${values?.firstName} ${values?.lastName}`,
          email: values?.email,
          phone: values?.phoneNumber,
          subject: values?.subject,
          message: values?.message,
        };
        const contactUs = await contactFormApi(bodyData);

        if (contactUs && contactUs.code === 200) {
          toast.success(contactUs?.message);
          contactUsFormik.resetForm();
        }
      } catch (error) {
        // Handle errors here
        console.error("An error occurred:", error);
      }
    },
  });
  // States declaration
  const [value, setValue] = React.useState("1");
  const [selectedApplicationUsage, setSelectedApplicationUsage] = useState(1);

  // Functions declaration
  const handleSignUp = () => {
    router.replace("/auth/signup");
  };
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handleSelectedApplicationUsage = (number: number) => {
    setSelectedApplicationUsage(number);
  };

  const handleScrollcontact = () => {
    const element = document.getElementById("scrollToContact");

    element && element.scrollIntoView();
  };
  useEffect(() => {
    // Get device model using userAgent
    const model = navigator.userAgent;

    // Get app version from package.json
    const version = getAppVersion();
    localStorage.setItem("deviceModel", model);
    localStorage.setItem("appVerison", version);
    localStorage.setItem("deviceKind", "web");
    localStorage.setItem("deviceId", uuidv4());
  }, []);
  return {
    handleSignUp,
    contactUsFormik,
    value,
    handleChange,
    handleSelectedApplicationUsage,
    selectedApplicationUsage,
    handleScrollcontact,
  };
};
