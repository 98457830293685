import React, { useEffect } from "react";
// import VisitorHeader from "@/Components/Common/Headers/VisitorHeader";
// import BannerSection from "./BannerSection";
import { useLanding } from "./useLanding";
import dynamic from "next/dynamic";

const FullScreenLoader = dynamic(() => import("@/Components/Common/FUllScreenLoader/FullScreenLoader"));
const LandingFooter = dynamic(() => import("@/Components/Common/Footer/LandingFooter"));
const AboutSection = dynamic(() => import("./AboutSection"));
const ApplicationSection = dynamic(() => import("./ApplicationSection"));
const UsersSection = dynamic(() => import("./UsersSection"));
const ContactSection = dynamic(() => import("./ContactSection"));
const VisitorHeader = dynamic(() => import("@/Components/Common/Headers/VisitorHeader"));
const BannerSection = dynamic(() => import("./BannerSection"));

const Landing = () => {
  const {
    handleSignUp,
    contactUsFormik,
    value,
    handleChange,
    handleSelectedApplicationUsage,
    selectedApplicationUsage,
    handleScrollcontact,
  } = useLanding();

  return (
    <div>
      <VisitorHeader handleScrollcontact={handleScrollcontact} />
      <BannerSection />
      <AboutSection />
      <ApplicationSection
        handleSelectedApplicationUsage={handleSelectedApplicationUsage}
        selectedApplicationUsage={selectedApplicationUsage}
      />
      <UsersSection handleSignUp={handleSignUp} value={value} handleChange={handleChange} />
      <ContactSection contactUsFormik={contactUsFormik} />
      <LandingFooter />
      <FullScreenLoader />
    </div>
  );
};

export default Landing;
